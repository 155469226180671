/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import '@fontsource/cinzel' // Defaults to weight 400.
import React from 'react'
import { ParallaxProvider } from 'react-scroll-parallax'

export const wrapPageElement = ({ element, props }) => {
  return <ParallaxProvider>{element}</ParallaxProvider>
}
